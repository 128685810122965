// Pass in a class for the parent, then apply fill and stroke color (optional)
@mixin svg-variant($parent, $fill-color, $stroke-color: inherit) {
  #{$parent} {
    * {
      fill: $fill-color;
      stroke: $stroke-color;
    }
  }
}

// Generate SVG color classes from the svg-variant mixin
@each $color, $value in $theme-colors {
  @include svg-variant(".svg-#{$color}", $value);
}