main.fleet-info {
  .fleet-info__intro {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .fleet-info__toolbar {
    .fleet-info__search-form {
      .form-control {
        &::placeholder {
          color: $blue !important;
          font-weight: 500;
        }
      }

      #reset-form {
        min-width: 200px;

        &.disabled {
          cursor: auto;
        }
      }
    }
  }

  .no-results {
    display: none;
    width: calc(100% - 200px - #{map_get($spacers, 3)});
    background-color: transparentize($paleaqua, 0.5);

    a {
      text-decoration: underline;
    }
  }

  .fleet-info__pdf-download {
    @media screen and (max-width: breakpoint-max(sm)) {
      @include button-brand($lightaqua, $blue, $blue, $lightaqua, 12px, $white, $blue);
      padding: map_get($spacers, 3);
      font-size: $font-size-p-small;
      font-weight: 500;
      @include z-depth-1;

      i {
        display: none;
      }
    }

    @media screen and (min-width: breakpoint-min(md)) {
      font-size: $font-size-p-small;
      font-family: $font-family-sans-serif;
      font-weight: normal;
      text-transform: unset !important;
      text-decoration: underline;
      box-shadow: none;
    }
  }

  .reveal-gradient {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 1px;
      right: 0;
      width: 15px;
      height: calc(100% - 1px);
      z-index: 2;

      @media screen and (min-width: breakpoint-min(sm)) and (max-width: breakpoint-max(lg)) {
        box-shadow: inset -15px 0px 10px -8px rgba(0,0,0,0.4);
      }
    }

    .scroll-container {
      width: 100%;
      overflow-x: auto;
      z-index: 1;
    }
  }

  .fleet-info__table {
    font-size: $font-size-base * 0.875;
    z-index: 0;

    thead {
      th {
        padding: map_get($spacers, 3);
        border-bottom: 5px solid $white;
        font-weight: normal;

        &:first-child {
          font-weight: bold;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 2px solid $fathomblue;

        td {
          height: 100%;
          padding: map_get($spacers, 2) map_get($spacers, 3);
          vertical-align: middle;

          &:first-child {
            font-weight: bold;
          }

          &:last-child {
            @media screen and (min-width: breakpoint-min(sm)) and (max-width: breakpoint-max(lg)) {
              padding-right: map_get($spacers, 4);
            }
          }
        }

        &.no-results {
          display: none;
        }
      }

      tr:nth-of-type(odd) {
        background-color: $paleaqua;
      }

      tr:nth-of-type(even) {
        background-color: transparentize($paleaqua, 0.7);
      }
    }
  }

}