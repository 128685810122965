// Desktop nav tab styling
.nav-tabs {
  &#desktop-tabs {
    @media screen and (max-width: breakpoint-max(sm)) {
      display: none;
    }
  }

  &.desktop-tabs--team {
    @media screen and (max-width: breakpoint-max(md)) {
      display: none;
    }
  }

  .nav-item {
    min-width: 210px;
    color: $fathomblue;
    background-color: $gray-400;
    font-weight: 500;

    @media screen and (max-width: 910px) {
      min-width: 170px;
    }

    .nav-link {
      position: relative;
      border: 0;
      border-radius: 0;
      font-family: $font-family-serif-alt;
      font-size: $font-size-p-small;
      font-weight: 500;
      transition: 0.2s;

      &:hover {
        color: $white;
        background-color: $lightaqua;
      }

      &.active {
        background-color: $blue;
        color: $white;

        span {
          position: relative;

          &::before {
            background-color: $lightaqua;
          }
        }
      }

      &:hover {
        span {
          &::before {
            background-color: $white;
          }
        }
      }

      span {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 50%;
          height: 2px;
          width: 100%;
          transform: translate(-50%, 0);
          transition: 0.2s;
        }
      }
    }
  }

  @media screen and (max-width: breakpoint-max(sm)) {
    .nav-item {
      display: none;
    }
  }
}

// Mobile dropdown toggle styling
#mobile-tab-toggle {
  width: 100%;
  font-family: $font-family-serif-alt;
  font-weight: 600;
  font-size: $font-size-base * 1.25;

  &.mobile-tab-toggle--fleet-info {
    @media screen and (min-width: breakpoint-min(md)) {
      display: none;
    }
  }

  &.mobile-tab-toggle--team {
    @media screen and (min-width: breakpoint-min(lg)) {
      display: none;
    }
  }

  &.show {
    .nav-link {
      i {
        transform: rotate(90deg);
      }
    }
  }

  .nav-link {
    color: $fathomblue;
    background-color: #D1D2D1;
    font-weight: 600;

    i {
      transition: transform 0.3s;
    }
  }

  .dropdown-menu {
    background-color: #D1D2D1;
    width: 100%;
    border-radius: 0;
    border: 0;

    // Required to override '.nav' display rules.
    &:not(.show) {
      display: none;
    }

    .dropdown-item {
      color: $fathomblue;
      border-radius: 0;
      font-size: $font-size-base;
      text-align: center;

      &:hover {
        background-color: inherit;
      }

      &.active {
        background-color: initial;
        font-weight: 600;
      }
    }
  }
}