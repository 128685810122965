$accent-size: 30px;
$figure-max-size: 265px;

.team-figure-bios {
  .card {
    max-width: $figure-max-size;
    height: auto;
    cursor: pointer;

    .img-wrapper {
      position: relative;
      max-width: $figure-max-size;
      max-height: $figure-max-size;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        right: 0;
        border-left: $accent-size solid transparent;
        border-top: $accent-size solid transparent;
        transition: 0.3s;
      }

      &.accent-yellow::after {
        border-bottom: $accent-size solid $yellow;
      }

      &.accent-aqua::after {
        border-bottom: $accent-size solid $aqua;
      }
    }

    &[aria-expanded=true] {
      .accent-yellow::after {
        border-bottom: $accent-size solid $aqua;
      }

      .accent-aqua::after {
        border-bottom: $accent-size solid $yellow;
      }
    }

    .card-body {
      .card-text {
        color: $blue;
        font-family: $font-family-serif-alt;
        text-align: center;
        text-transform: uppercase;

        &__heading {
          font-weight: 500;
        }

        &__subheading {
          font-size: 0.75rem;
        }
      }
    }

    .bio-arrow {
      position: relative;
      width: 120px;

      &.bio-arrow-yellow {
        span {
          background-color: $yellow;
        }
      }

      &.bio-arrow-aqua {
        span {
          background-color: $aqua;
        }
      }

      span {
        display: inline-block;
        position: absolute;
        top: 0;
        width: 60px;
        height: 2px;
        transition: 0.3s;
      }

      span:nth-of-type(1) {
        left: 0;
        transform-origin: left top;
      }

      span:nth-of-type(2) {
        right: 0;
        transform-origin: right top;
      }
    }

    &[aria-expanded=true] {
      .bio-arrow-yellow {
        span {
          background-color: $aqua;
        }
      }

      .bio-arrow-aqua {
        span {
          background-color: $yellow;
        }
      }

      span:nth-of-type(1) {
        width: 65px;
        transform: rotate(20deg);
      }

      span:nth-of-type(2) {
        width: 65px;
        transform: rotate(-20deg);
      }
    }
  }
}