// Page styling
.content-block {
  padding-top: $content-block-spacing;
  padding-bottom: $content-block-spacing;
}

// Background image defaults
.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-img--contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

// Use this class when an elements height is too large to display 'background-image: cover' by itself without causing
// pixellation.
.bg-img--fixed {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.obj-fit-contain {
  object-fit: contain !important;
}

.obj-fit-cover {
  object-fit: cover !important;
}

.white-space-normal {
  white-space: normal !important;
}

.border-box {
  background-clip: border-box !important;
}