.form-control {
  padding: map_get($spacers, 4);
  border-radius: 0;
  font-family: $font-family-serif-alt;
  font-size: $font-size-form-input;
  color: $blue;
  text-transform: uppercase;

  &:focus {
    color: $blue;
  }

  &::placeholder {
    color: $blue;
    font-weight: bold;
  }

  &.form-control--ie {
    height: 48px;
  }
}