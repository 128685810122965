.contact-form {
  .form-control {
    color: $fathomblue;

    &:focus {
      color: $fathomblue;
    }

    &::placeholder {
      color: $fathomblue;
    }
  }

  @media screen and (max-width: breakpoint-max(sm)){
    .grecaptcha-badge {
      margin: 0 auto;
    }
  }
}