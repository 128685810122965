main.careers {
  .careers__search-content {
    .search-block__body {
      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .input-group {

      .input-group-prepend {
        background-color: $white;

        i {
          color: $lightaqua
        }
      }

      input[type=text] {
        position: relative;
        height: 36px;
        padding: map_get($spacers, 3);
        color: $fathomblue;
        font-size: $font-size-base * 0.75;
        font-weight: 500;

        @media screen and (min-width: breakpoint-min(lg)) {
          flex: 0 0 auto;
          width: 240px;
        }

        &::placeholder {
          color: $fathomblue;
        }
      }

      .input-group-append {
        button[type=submit] {
          min-width: 10px;
          background-color: $lightaqua !important;

          i {
            font-size: $font-size-base;
          }

          @media screen and (max-width: breakpoint-max(sm)) {
            background-color: $white !important;

            i {
              color: $white;
            }
          }
        }
      }
    }
  }

  .careers__toolbar {
    .toolbar-form__select {
      flex: 0 1 auto;

      @media screen and (min-width: breakpoint-min(lg)) {
        width: 170px;
      }
    }

    .select-arrow {
      position: relative;

      &::after {
        content: '\f054';
        position: absolute;
        display: inline-block;
        top: 50%;
        right: map_get($spacers, 2);
        width: auto;
        height: auto;
        color: $lightaqua;
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        pointer-events: none;
        transform: translateY(-50%);
      }

      select, .custom-select {
        background-image: none;

        option {
          background-color: $lightaqua;
        }
      }
    }

    .custom-select {
      color: $fathomblue;
      background-color: $white;
      height: auto;
      border-radius: 0 !important;
      font-family: $font-family-serif-alt;
      font-size: $font-size-base * 0.75;
      font-weight: 500;
    }

    #filters-submit {
      min-width: 120px;
    }
  }

  .search-terms {
    background-color: transparentize($paleaqua, 0.5);

    .close {
      opacity: 1;
    }
  }

  .careers__listings {
    .careers__listing {
      border-top: 1px solid $lightaqua;
      transition: background-color 0.2s;

      &:last-child {
        border-bottom: 1px solid $lightaqua;
      }

      &:hover {
        color: $fathomblue !important;
        background-color: transparentize($aqua, 0.9);
      }

      .listing__details {
        @media screen and (min-width: breakpoint-min(md)) {
          min-width: 160px;
          margin-right: map_get($spacers, 3);
          border-right: 1px solid $lightaqua;
        }
      }

      .listing__info {
        .listing__title {
          font-weight: 500;
        }

        .listing__body {
          p {
            margin-bottom: 0;
          }
        }
      }

      .listing__cta {
        min-width: 24px;

        .cta-arrow {
          height: 2.375rem;
          width: auto;

          @media screen and (max-width: breakpoint-max(sm)) {
            height: 1.125rem;
          }
        }
      }
    }
  }
}

main.careersVacancyDetail {
  .vacancy__detail {
    .info-group {
      font-family: $font-family-serif-alt;
      font-size: $font-size-p-small;
      font-weight: 500;
      text-transform: uppercase;
    }

    .pipe {
      padding: 0 map_get($spacers, 2);
      color: $lightaqua;
      font-weight: bold;
    }

    .return-cta-banner {
      a {
        color: $fathomblue;
      }
    }
  }
}