.split-banners {
  .split-banner {
    // Img/text order should alternate for each banner instance
    &:nth-child(even) {
      .split-banner__content {
        flex-direction: row-reverse;

        @media screen and (max-width: breakpoint-max(sm) ) {
          flex-direction: column;
        }
      }
    }

    .split-banner__content {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: breakpoint-max(sm)) {
        flex-direction: column;
      }

      .logo {
        width: auto;
        max-width: 100%;
        max-height: 70px;
      }

      .img-container {
        display: flex;
        width: 50%;
        height: auto;

        @media screen and (max-width: breakpoint-max(sm)) {
          width: 100%;
          height: 300px;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }

        &__compat {
          img {
            opacity: 0;
          }
        }
      }
    }
  }
}