$terminal-colors: map_get($theme-colors, 'light-aqua'), map_get($theme-colors, 'blue'), map_get($theme-colors, 'yellow'), map_get($theme-colors, 'stone'), map_get($theme-colors, 'pink');

main.contactUsTerminalLocations {
  .location-category {
    margin-bottom: map_get($spacers, 5);

    // Rem unit used in order to achieve more control over the spacing required to achieve margin as per designs.
    @media screen and (min-width: breakpoint-min(sm)) {
      margin-bottom: 4.5rem;
    }

    .address-block {
      margin-top: map_get($spacers, 3);
      padding: 0 0 0 map_get($spacers, 3);
      border-left-width: 1px;
      border-left-style: solid;

      p {
        &:not(:last-of-type) {
          margin-bottom: 0;
        }
      }

      .contact-info {
        .btn {
          color: $white;
          min-width: auto;
        }

        // As requested, these classes are used to imitate the assumed space of a button/phone number, when no button is
        // present to ensure contact info still aligns correctly with other addresses.
        .spacer {
          &--phone-number {
            height: calc(#{$font-size-base} * #{$line-height-base});
          }

          &--btn {
            height: calc((#{$btn-padding-y} * 2) + (#{$font-size-base} * 1.125));
            margin-top: map_get($spacers, 3);
          }
        }
      }
    }
  }
}