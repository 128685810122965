$btn-accent-size: 12px;

// This mixin allows us to not only define hover colors for the button bg and text, but also for the corner accent too.
@mixin button-brand($background-color, $hover-background-color, $accent-color, $hover-accent-color, $accent-size: $btn-accent-size, $color: color-yiq($background-color), $hover-color: color-yiq($hover-background-color)) {
  color: $color !important;
  background-color: $background-color;

  &.btn-accent {
    &::after {
      border-bottom: $accent-size solid $accent-color;
    }

    &:hover:not(.no-hover) {
      &::after {
        border-bottom: $accent-size solid $hover-accent-color;
      }
    }
  }

  &:hover:not(.no-hover) {
    color: color-yiq($hover-background-color) !important;
    background-color: $hover-background-color;
  }
}

.btn {
  position: relative;
  min-width: 200px;
  padding: $btn-padding-y $btn-padding-x;
  border-radius: 0;
  border: none;
  text-transform: uppercase;
  font-family: $font-family-serif-alt;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;

  &:hover {
    @include z-depth-1-half;
  }

  &.btn-accent {
    @include z-depth-1;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-left: $btn-accent-size solid transparent;
      border-top: $btn-accent-size solid transparent;
      transition: 0.3s ease;
    }
  }

  @media screen and (max-width: breakpoint-max(xs)) {
    display: block;
    padding: map_get($spacers, 2) map_get($spacers, 5);
  }

  // SCF Brand buttons as per brand guidelines. Defines the various hover states and their exceptions where necessary.
  // Where 'light' and 'dark' modifiers are used, these refer to the hover state.
  @each $color, $value in $theme-colors {
    &.btn-#{$color} {
      @if $value == $white {
        @include button-brand($white, $blue, $blue, $lightaqua);

        &--alt {
          @include button-brand($white, $lightaqua, $lightaqua, $white);
        }
      }

      @elseif $value == $lightaqua {
        @include button-brand($lightaqua, $white, $blue, $lightaqua);

        &--alt {
          @include button-brand($lightaqua, $white, $white, $lightaqua);
        }

        &--light {
          @include button-brand($lightaqua, $white, $white, $lightaqua);
        }

        &--dark {
          @include button-brand($lightaqua, $blue, $blue, $lightaqua);
        }
      }

      @elseif $value == $blue {
        @include button-brand($blue, $white, $white, $blue);

        &--alt {
          @include button-brand($blue, $white, $lightaqua, $blue);
        }
      }

      @elseif $value == $stone {
        @include button-brand($stone, $white, $white, $stone, $color: $white);
      }

      @else {
        @include button-brand($value, $white, $white, $value);
      }
    }
  }

  &.btn-transparent {
    @include button-brand("transparent", $gray-300, $white, $primary, $color: $white);

    &:not(:hover, .active) {
      box-shadow: none !important;
    }
  }
}
