.article-card {

  &__wrapper {
    margin: -1px;
    padding-top: calc(#{map_get($spacers, 4)} + 1px);

    @media screen and (min-width: breakpoint-min(md)) {
      transition: 0.2s;

      &:hover {
        background-color: transparentize($paleaqua, 0.5);
      }
    }
  }

  &__details {
    span {
      position: relative;
      padding-right: calc(#{map_get($spacers, 2)} + 1px);
      font-family: $font-family-serif-alt;
      font-weight: $font-weight-normal;
      font-size: $font-size-p-small;
      text-transform: uppercase;
      margin-right: map_get($spacers, 2);


      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          height: 13px;
          width: 1px;
          transform: translateY(-18%);
          background-color: map_get($theme-colors, "secondary");
        }
      }
    } // End of span styling

    a {
      font-family: $font-family-serif-alt;
      font-weight: $font-weight-bold;
      font-size: $font-size-p-small;
      color: map_get($theme-colors, "fathom-blue");
    }
  } // End of __detail styling

  .article-card__cta {
    min-width: 24px;

    .cta-arrow {
      height: 2.375rem;
      width: auto;

      @media screen and (max-width: breakpoint-max(sm)) {
        height: 1.125rem;
      }
    }
  }

  &__hr {
    width: 100%;
    background-color: map_get($theme-colors, "secondary");

    @media only screen and (max-width: breakpoint-max("sm")) {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
    }
  }
}