// Hero content is overlaid onto background image on tablet/desktop, but sits below the hero image on mobile as to ensure
// it doesn't obscure/cover the background image.

.hero {
  position: relative;
  width: 100%;

  &--news {
    height: 500px;

    @media screen and (max-width: breakpoint-max(xs)) {
      height: 240px;
    }
  }

  .hero__bg-img-wrapper {
    position: relative;
    height: 500px;

    @media screen and (max-width: breakpoint-max(xs)) {
      height: 240px;
    }

    // This acts as a fallback gradient in the event an image doesn't load.
    &::before {
      content: "";
      position: absolute;
      max-width: 360px;
      width: calc(100% - (2 * #{map_get($spacers, 3)}));
      height: 100%;
      left: 50%;
      top: 50%;
      padding: 0 map_get($spacers, 3);
      background-image: url('../img/scf-logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      opacity: 0.3;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -2;
      background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(209,210,209,1) 100%);
    }
  }

  .container--hero-content {
    @media screen and (min-width: breakpoint-min(sm)) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .hero__content-wrapper {
      height: 100%;

      .hero__content {
        background-color: $blue;
        border-bottom: 5px solid $lightaqua;
        position: relative;

        @media screen and (max-width: breakpoint-max(xs)) {
          h1 {
            font-size: $font-size-base * 1.375;
          }
        }
      }
    }
  }
}
