main.aboutUsMeetTheTeam {
  .sub-category__heading {
    font-weight: 500;
    border-bottom: 1px solid $lightaqua;
    padding-left: map_get($spacers, 4);

    @media screen and (max-width: breakpoint-max(xs)) {
      border-bottom: 3px solid $lightaqua;

      // Some funky map_get calc needed so category headings are aligned with team member details
      padding-left: calc(#{map_get($spacers, 3)} * 2);
    }
  }

  .team-member-group {
    &:nth-child(n+2) {
      margin-top: map_get($spacers, 5);
    }

    // First member in solo group needs a border top.
    &--solo {
      .team-member__card {
        &:first-of-type {
          border-top: 1px solid $lightaqua;
        }
      }
    }

    .team-member__card {
      border-bottom: 1px solid $lightaqua;
      font-family: $font-family-serif-alt;
      font-size: $font-size-p-small;

      .team-member__details {
        text-transform: uppercase;

        @media screen and (min-width: breakpoint-min(md)) {
          border-right: 1px solid $paleaqua;
        }

        .team-member__name {
          font-weight: 500;
        }
      }

      .team-member__contact {
        .contact-link {
          color: $fathomblue;
          transition: 0.1s;

          &:hover {
            color: $lightaqua;
          }
        }
      }

      .team-member__details, .team-member__contact {
        padding: 0 map_get($spacers, 4);

        @media screen and (max-width: breakpoint-max(xs)) {
          padding: 0 map_get($spacers, 3);
        }
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}