.news-article-slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 300px !important;
  padding: map_get($spacers, 4) map_get($spacers, 3);
  background-color: $white;
  border-bottom: 2px solid map_get($theme-colors, "blue");

  // Margin to cater for when slides sit two aside
  @media screen and (max-width: breakpoint-max(md)) {
    margin: 0 map_get($spacers, 3);
  }

  &__title {
    h3 {
      font-weight: 500;
    }
  }

  &__details {
    justify-self: flex-end;

    span {
      position: relative;
      padding-right: calc(#{map_get($spacers, 2)} + 1px);
      font-family: $font-family-serif-alt;
      font-weight: $font-weight-normal;
      font-size: $font-size-p-small;
      text-transform: uppercase;
      margin-right: map_get($spacers, 2);

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        height: 13px;
        width: 1px;
        transform: translateY(-50%);
        background-color: map_get($theme-colors, "fathom-blue");
      }

    }

    a {
      position: relative;

      &:hover {
        svg {
          * {
            fill: map_get($theme-colors, "blue");
          }
        }
      }

      svg {
        position: absolute;
        height: 20px;
        right: -25px;
        bottom: 0;

        * {
          fill: map_get($theme-colors, "light-aqua");
          transition: fill 0.15s;
        }
      }
    }

  }
}