// Two class chain needed to add more specificity than BS card rules
.card.generic-card {
  border-bottom: 2px solid $primary;
  transition: background-color 0.3s;

  &.card--hover {
    &:hover {
      background-color: mix($white, $paleaqua, 80%);
    }
  }

  .card-header {
    height: 235px;
    background-color: $primary;
    border: 0;
    border-radius: 0;

    .scf-fallback {
      width: 162px;
      height: auto;
    }
  }

  .card-body {
    // Custom spacer unit needed here for 'y' padding
    padding: calc(#{$spacer} * 2) map_get($spacers, 4);

  }
}
