main.policies {
  background-color: transparentize($paleaqua, 0.5);
  .policy-title {
    margin: 4rem 0;

    @media screen and (max-width: breakpoint-max(sm)) {
      margin: 3rem map_get($spacers, 3) 2rem map_get($spacers, 3);
    }

    h1 {
      font-family: $font-family-serif-alt;
      font-weight: 300;

      @media screen and (max-width: breakpoint-max(xs)) {
        font-size: 3rem;
      }
    }

    .bullet {
      height: 13px;
      width: 40px;

      &::after {
        height: 13px;
        width: 13px;
      }
    }
  }

  .policy-copy {
    ul {
      li {
        margin-bottom: map_get($spacers, 3)
      }
    }
  }

}