#global-nav {
  // Fixes Safari and Chrome mobile issue. 'flex-wrap: wrap' added to #main-nav to prevent 0 shrink allowing nav items to appear off page
  flex: 1 0 auto;

  @include z-depth-3;
  color: $darkgray;
  font-family: $font-family-serif-alt;
  font-size: $font-size-p-small;
  font-weight: 500;
  z-index: 1;

  // Navbar container constrains width on desktop, but set to max width on collapse
  .container {
    &__nav {
      position: relative;
      @media screen and (max-width: breakpoint-max(md)) {
        max-width: 100%;
      }
    }
  }

  // General styling to nav-links and dropdown items
  .nav-item {
    .nav-link, .dropdown-item span:not(.no-accent) {
      position: relative;
      color: $fathomblue;
      font-weight: 500;
      letter-spacing: 0.85px;
      text-transform: uppercase;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.2s;

      &::before {
        content: "";
        position: absolute;
        opacity: 0;
        height: 3px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        transition: 0.4s;
      }
    }

    .nav-link {
      &::before {
        width: calc(100% - (#{$nav-link-padding-x}));
      }
    }

    .dropdown-item {
      span {
        &::before {
          width: 100%;
        }
      }
    }
  }

  // Contact 'widget' on desktop, displayed center under main nav links on mobile
  .navbar-contact {
    position: relative;
    margin-top: map_get($spacers, -2);

    a {
      color: $white;
      font-size: $small-font-size;
      font-weight: 500;
      cursor: pointer;
    }

    @media screen and (min-width: breakpoint-min(lg)) and (max-width: breakpoint-max(lg)) {
      margin-right: $spacer * 1;
    }

    @media screen and (min-width: breakpoint-min(xl)) {
      margin-right: $spacer * 2;
    }

    @media screen and (min-width: breakpoint-min(lg)) {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: map_get($spacers, -2);
      background-color: transparentize($stormgrey, 0.8);

      a {
        color: $fathomblue;
      }
    }
  }

  // General L3 dropdown menu group styling
  .menu-group__dropdown-item-wrapper {
    .dropdown-item {
      position: relative;

      // Hover/active underlines on screens 'md' and upwards
      @media screen and (min-width: breakpoint-min(md)) {
        &::before {
          content: "";
          position: absolute;
          height: 3px;
          bottom: 0;
          left: map_get($spacers, 3);
          transition: 0.4s;
          opacity: 0;
          background-color: $nav-node-active-accent-color-alt;
        }

        &:hover, &.active {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (min-width: breakpoint-min(md)) and (max-width: (breakpoint-max(lg))) {
    #navbarNavDropdown {
      margin: 0 -1rem;
    }
  }

  .dropdown-menu {
    border-radius: 0;
    border: 0;
    background-clip: border-box;
  }

  #main-nav {
    flex-wrap: wrap;

    .dropdown-toggle {
      &::after {
        @media screen and (max-width: breakpoint-max(md)) {
          border-top-color: $white !important;
        }

        border-top-color: $fathomblue !important;
      }
    }

    // 2nd Level dropdown styling
    .dropdown-l2 {
      > .dropdown-menu-l2 {
        border-bottom: 1px solid $white;

        .dropdown-item, .dropdown-item span {
          color: $white;

          &.active {
            color: transparentize($white, 0.5);
          }
        }
      }
    }

    //////////////////////////////////////////////
    // Styling nav-links for desktop viewports //
    ////////////////////////////////////////////

    @media screen and (min-width: breakpoint-min(lg)) {
      &.nav-cta-is-present {
        // Custom spacer increment required here
        padding-top: $spacer * 2 !important;
      }

      .nav-item {
        position: relative;

        &.show {
          // If dropdown menu is shown or hovered, nav item has background color
          &.dropdown.show {
            background-color: $midgray;
          }

          .nav-link {
            // If dropdown menu is open, underline is blue and permanent
            &::before {
              opacity: 1;
              background-color: $nav-node-active-accent-color;
            }
          }
        }

        .nav-link {
          &:hover {
            &::before {
              opacity: 1;
              background-color: $nav-node-hover-accent-color;
            }
          }

          &.active {
            &::before {
              opacity: 1;
              background-color: $nav-node-active-accent-color;
            }
          }
        }
      }

      // Pipes/dividers between each nav-item
      .nav-item:not(:last-child)::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 1rem;
        top: 50%;
        right: 0;
        background-color: $gray-400;
        transform: translateY(-50%);
      }

      .dropdown-l1 {
        > .dropdown-menu-l1 {
          min-width: 11rem;
          margin: 0 auto;
          border-radius: 0;
          border-width: 0 0 3px 0;
          border-bottom: 3px solid $lightaqua;
          background-color: $nav-dropdown-menu-l1-bg-color;

          .dropdown-item, .dropdown-toggle {
            color: $blue;
            font-size: $small-font-size;

            span {
              padding-bottom: map_get($spacers, 1);

              &:hover::before {
                opacity: 1;
                background-color: $nav-node-hover-accent-color-alt;
              }
            }

            &:not(:first-child) {
              margin-top: map_get($spacers, 3)
            }

            &.active {
              background-color: inherit;
              font-weight: bold;

              span {
                &::before {
                  opacity: 1;
                  background-color: $nav-node-active-accent-color-alt;
                }
              }
            }

            &:hover, &:active, &:focus {
              background-color: inherit !important;
            }
          }

          // Level 2 dropdown menu styling
          > .dropdown-l2 {
            position: relative;

            > a.dropdown-toggle {
              // Remove BS default dropdown toggle caret
              &::after {
                display: none;
              }

              i {
                color: $nav-dropdown-l2-caret-color;
                transform-origin: center center;
                transition: transform 0.3s;
              }

              &.active {
                i {
                  transform: rotate(-90deg);
                }
              }
            }

            .dropdown-menu-l2 {
              top: 0;
              left: 100%;
              width: auto;
              margin-top: -$spacer * 0.75;
              white-space: nowrap;
              background-color: $nav-dropdown-menu-l2-bg-color;
              border-left: 3px solid $lightaqua;
              border-bottom: 0;

              > .dropdown-menu-l2__menu-group {
                &:first-of-type {
                  padding-top: map_get($spacers, 2);
                }

                &:not(:first-of-type) {
                  .menu-group__heading {
                    margin-top: map_get($spacers, 3);
                  }
                }

                &--accent {
                  &:not(:last-of-type) {
                    position: relative;

                    &::after {
                      content: "";
                      display: block;
                      position: absolute;
                      bottom: 0;
                      left: map_get($spacers, 3);
                      width: calc(100% - (#{map_get($spacers, 3)} * 2));
                      height: 1px;
                      margin: 0 auto;
                      background-color: $white;
                    }
                  }
                }

                .menu-group__dropdown-item-wrapper {
                  .dropdown-item {
                    display: inline;
                    color: $white;

                    &::before {
                      left: 50%;
                      width: calc(100% - (#{map_get($spacers, 4)} * 2));
                      transform: translateX(-50%);
                    }
                  }
                }

                .dropdown-item {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }

  ////////////////////////////////////////////////////
  // Styling nav-links for mobile/tablet viewports //
  //////////////////////////////////////////////////

  @media screen and (max-width: (breakpoint-max(md))) {
    #navbarNavDropdown {
      background-color: $fathomblue;
      color: $white;

      #main-nav {
        .nav-link {
          color: $white;
          display: unset;
        }

        // Underline states on nav-items
        .nav-item {
          padding: map_get($spacers, 3) 0;

          .nav-link {
            position: relative;

            &::before {
              width: 100%;
            }

            &.active::before, &:hover::before {
              opacity: 1;
              background-color: $nav-node-active-accent-color-alt;
            }
          }
        }

        .dropdown-l1 {
          &.show {
            > .dropdown-toggle {
              &::before {
                background-color: $nav-node-active-accent-color-alt;
                opacity: 1;
              }
            }
          }

          .dropdown-menu-l1 {
            margin: 0;
            background-color: $nav-dropdown-menu-l1-bg-color-mobile;

            &.show {
              margin-top: map_get($spacers, 2);
            }

            li {
              border-bottom: 1px solid $white;
            }

            .dropdown-l2 {
              > a.dropdown-toggle {
                // Remove BS default dropdown toggle caret
                &::after {
                  display: none;
                }

                i {
                  color: $nav-dropdown-l2-caret-color-mobile;
                }
              }

              .dropdown-menu-l2 {
                background-color: $nav-dropdown-menu-l2-bg-color-mobile;
                border-radius: 0;
                border: 0;

                &.show {
                  margin-top: map_get($spacers, 3);
                }



                &__menu-group {
                  .menu-group__dropdown-item-wrapper {
                    .dropdown-item {
                      font-weight: 500;
                      white-space: normal;
                    }
                  }
                }
              }
            }

            // Covers all dropdown-items nested within .dropdown-menu-l1 elements
            .dropdown-item {
              padding: map_get($spacers, 1) map_get($spacers, 3);
              font-size: $small-font-size;

              span {
                color: $white;
                padding-bottom: map_get($spacers, 1);

                &:hover::before {
                  opacity: 1;
                  background-color: $nav-node-hover-accent-color-alt;
                }
              }

              &.active {
                background-color: inherit;
                font-weight: bolder;

                span::before {
                  opacity: 1;
                  background-color: $nav-node-active-accent-color-alt;
                }
              }

              &:active, &:focus, &:hover {
                background-color: unset;
              }

              &:not(:first-child) {
                margin-top: map_get($spacers, 3)
              }
            }
          }
        }
      }
    }
  }
}