.service-groups {
  .service-group {
    .service-group__service {
      &:nth-child(n+2) {
        @media screen and (max-width: breakpoint-max(sm)) {
          margin-top: map_get($spacers, 5);
        }
      }

      .btn-accent {
        min-width: unset;
        width: 100%;
      }
    }

    &.service-group__border {
      @media screen and (max-width: breakpoint-max(md)) {
        border-bottom: 1px solid transparentize($white, 0.5);
      }

      @media screen and (min-width: breakpoint-min(lg)) {
        border-right: 1px solid transparentize($white, 0.5);
      }
    }
  }
}