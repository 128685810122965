.service-slide-item {
  h5 {
    display: flex;
    align-items: flex-end;
    font-size: $font-size-base * 1.125;
    min-height: 42px;
  }

  .service-icon {
    svg {
      width: 130px;
      height: 130px;
    }
  }

  .slide-content {
    &__heading {
      font-family: $font-family-serif-alt;
      font-weight: 500;
    }
  }
}