// Brand Colors

// -- Primary Palette
$blue:          #005191;
$aqua:          #0081AD;
$lightaqua:     #19A4C8;
$paleaqua:      #97CAE0;
$stormgrey:     #B2B4B2;
$fathomblue:    #1E4258;


// -- Accent Palette
$stone:         #CD9967;
$pink:          #A4123F;
$yellow:        #FFA300;


// -- Theme Palette
$primary:       $blue;
$secondary:     $aqua;
$people:        $stone;
$response:      $pink;
$alert:         $yellow;


// Greys - Using SASS lighten/darken (HSL lightening/darkening)
$white:    #fff;
$gray-100: lighten($stormgrey, 40%);
$gray-200: lighten($stormgrey, 30%);
$gray-300: lighten($stormgrey, 20%);
// Exception needed here for $gray-400 to mediate the many usages of grays not defined in the brand guidelines.
$gray-400: #D1D2D1;
$gray-500: $stormgrey;
$gray-600: darken($stormgrey, 10%);
$gray-700: darken($stormgrey, 20%);
$gray-800: darken($stormgrey, 30%);
$gray-900: darken($stormgrey, 40%);
$black:    #000 !default;


// -- Greys for theme colors. This seems unnecessary given the map above, but the Invision designs seem to refer to gray
// shades used in SCH
$lightgray:     $gray-300;
$gray:          #F4F5F6;
$midgray:       #E1E1E1;
$darkgray:      #B2B4B2;


// -- Theme Colors
$theme-colors: ();
$theme-colors: map_merge((
        "white":      $white,
        "light-grey": $lightgray,
        "gray":       $gray,
        "mid-gray":   $midgray,
        "dark-gray":  $darkgray,
        "primary":    $primary,
        "secondary":  $lightaqua,
        "people":     $people,
        "response":   $response,
        "alert":      $alert,
        "blue":       $blue,
        "aqua":       $aqua,
        "light-aqua": $lightaqua,
        "pale-aqua":  $paleaqua,
        "stone":      $stone,
        "pink":       $pink,
        "yellow":     $yellow,
        "storm-grey": $stormgrey,
        "fathom-blue":$fathomblue
), $theme-colors);


$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge((
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
), $grays);


// Spacing
$spacer: 1rem !default;
$spacers: ();
$spacers: map-merge((
        -1: -($spacer * .25),
        -2: -($spacer * .5),
        -3: -$spacer,
        -4: -($spacer * 1.5),
        -5: -($spacer * 3),
        -6: -($spacer * 6)
), $spacers);


// Letter spacing
$letter-spacing-base: 0.125rem;


// Content Block Spacing
$content-block-spacing: ($spacer * 3);


// Font family - Serif Alternative
$font-family-serif-alt: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


// Heading 'bullet' accents
$headingAccentWidth: 1.5rem;
$headingAccentHeight: 0.5rem;


$font-size-base: 1rem;
$font-size-p-small: $font-size-base * 0.875;
$font-size-form-input: $font-size-base * 0.75;


// Global nav variables
$nav-dropdown-menu-l1-bg-color: $midgray;
$nav-dropdown-menu-l1-bg-color-mobile: $fathomblue;
$nav-dropdown-menu-l2-bg-color: $fathomblue;
$nav-dropdown-menu-l2-bg-color-mobile: lighten($fathomblue, 7.5%);
$nav-dropdown-l2-caret-color: $fathomblue;
$nav-dropdown-l2-caret-color-mobile: $white;
$nav-node-active-accent-color: $primary;
$nav-node-hover-accent-color: $lightaqua;
$nav-node-active-accent-color-alt: $lightaqua;
$nav-node-hover-accent-color-alt: $lightaqua;


// History 'section arrow' accent size
$section-triangle-accent-size: 15px;


// Social Media Icons
// Based on unicode values by fontawesome. See font awesome docs here... https://fontawesome.com/
$social-icons: ();
$social-icons: map-merge((
        "facebook": "\f39e",
        "instagram": "\f16d",
        "twitter": "\f099",
        "linkedin": "\f0e1",
        "googlePlus": "\f0d5",
        "youtube": "\f167",
        "vimeo": "\f27d"
), $social-icons);