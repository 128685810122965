.pagination {
  font-family: $font-family-serif-alt;
  font-weight: 500;

  .page-item {
    &.active {
      .page-link {
        background-color: $lightaqua;
        color: $white;
        border: 1px solid $lightaqua;
      }
    }

    &.disabled {
      opacity: 0.6;
    }

    &:not(.disabled):hover {
      .page-link {
        color: $white;
        background-color: $lightaqua;
      }
    }

    .page-link {
      padding: map_get($spacers, 1) map_get($spacers, 2);
      min-width: 34px;
      text-align: center;
      transition: 0.2s;

      i {
        color: $fathomblue;
      }
    }

    &:first-child, &:last-child {
      .page-link {
        border: 1px solid $stormgrey;
        border-radius: 0 !important;
      }
    }

    &:not(:last-child) {
      margin-right: map_get($spacers, 2);
    }
  }
}