main.news {

  .article {

    &__details {
      span {
        position: relative;
        padding-right: calc(#{map_get($spacers, 2)} + 1px);
        font-family: $font-family-serif-alt;
        font-weight: $font-weight-normal;
        font-size: $font-size-p-small;
        text-transform: uppercase;
        margin-right: map_get($spacers, 2);


        &:not(:last-of-type) {
          &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            height: 13px;
            width: 1px;
            transform: translateY(-18%);
            background-color: map_get($theme-colors, "secondary");
          }
        }
      }
    }

    &__footer {
      hr {
        background-color: map_get($theme-colors, "secondary");
      }
    }
  }
}