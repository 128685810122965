$our-companies-card-border-color: $gray;
$our-companies-card-hover-border-color: $lightaqua;

.company-cards__block {
  .card-wrapper {
    transition: 0.45s;
    background-color: transparentize($black, 0.6);

    &__hover {
      &--bg-dark:hover {
        background-color: transparentize($black, 0.4);
      }
    }
  }

  .card {
    background: none;
    transition: 0.3s;
    width: 240px;
    max-width: 240px;
    margin: 0 auto;

    &.active {
      @media screen and (max-width: breakpoint-max(sm)) {
        border-bottom: 1px solid $our-companies-card-hover-border-color;
      }
    }

    .card-img {
      // SVG caused issues on IE, setting height explicitly fixes
      height: 81px;
    }

    .card-header {
      border-bottom: 5px solid $our-companies-card-border-color;
      width: 240px;
      max-width: 240px;
      border-radius: 0;
      background-color: white;
      transition: border-bottom 0.3s;
    }

    @media screen and (min-width: breakpoint-min(md)) {
      &.card--hover {
        .card-header {
          transition: 0.3s;

          &:hover {
            border-bottom-color: $our-companies-card-hover-border-color;
          }
        }

        .card-header {
          transition: 0.3s;

          &:hover {
            border-bottom-color: $our-companies-card-hover-border-color;
          }
        }
      }

      .collapse {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
      }
    }

    &.active {
      .card-header {
        border-bottom-color: $our-companies-card-hover-border-color;
      }
    }
  }

  .card-body {
    position: relative;
    color: white;

    @media only screen and (min-width: breakpoint-min(md)) {
      width: 240px;
      max-width: 240px;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: $font-family-serif-alt;
    }

    .close {
      position: absolute;
      top: map_get($spacers, 1);
      right: 0;
      color: #FFFFFF;

      @media screen and (min-width: breakpoint-min(md)) {
        display: none;
      }
    }

    .card-title {
      text-transform: uppercase;
      font-family: $font-family-sans-serif;
      font-weight: 600;
    }

    .card-description {
      p {
        font-size: $font-size-p-small;
      }
    }
  }

  .card-footer {
    border: 0;

    &--pending {
      font-size: $font-size-p-small;
    }
  }
}
