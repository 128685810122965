$bullet-height: 8px;

.bullet {
  position: relative;
  width: 20px;
  height: $bullet-height;
  margin: map_get($spacers, 2) 0;
  background-color: $fathomblue;

  &::after{
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 10px;
    height: $bullet-height;
    background-color: inherit;
    transform-origin: bottom left;
    transform: skew(-45deg, 0deg);
  }
}